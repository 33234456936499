import { ReactComponent as ShareArrowIcon } from '@brand/icons/share-arrow.svg'
import clsx from 'clsx'
import styles from './icon-ctas.module.css'
import type { MouseEventHandler } from 'react'
import { Button } from '../button/button'

interface ShareButtonProps {
  'data-tag_selection'?: string
  onClick?: MouseEventHandler<HTMLButtonElement>
  listingCardRedesignVersion?: number
  className?: string
  isAGListingCard?: boolean
  isRentListingCard?: boolean
}

export function ShareButton({
  onClick,
  listingCardRedesignVersion,
  className,
  isAGListingCard,
  isRentListingCard,
  ...props
}: ShareButtonProps) {
  const isRedesignV2 = listingCardRedesignVersion === 2
  const classNameDerived = isRentListingCard
    ? styles.withRentStyles
    : isRedesignV2
    ? styles.shareButtonRedesignV2
    : clsx(styles.iconCtaStyle, styles.default, {
        [styles.shareButtonAG]: isAGListingCard,
      })

  const Component = isRedesignV2 || isRentListingCard ? Button : 'button'

  return (
    <Component
      data-tag_item="share_button"
      data-tag_selection={props['data-tag_selection']}
      data-tid="share"
      className={clsx(className, classNameDerived)}
      aria-label="share"
      onClick={onClick}
      variant={
        isRedesignV2
          ? 'agTertiaryRedesign'
          : isRentListingCard
          ? 'rentSrpTertiary'
          : undefined
      }
      rounded={isRedesignV2 ? true : undefined}
    >
      <ShareArrowIcon aria-hidden />
    </Component>
  )
}
