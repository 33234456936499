import { useCallback, useMemo } from 'react'
import { useRequestData } from '../../request-data/pages-router/use-request-data'
import { useOpenModal } from '../../modals/use-open-modal'
import { ModalName } from '../../modals/modals.config'
import type { UseShareProperty_ListingFragment } from './__generated__/use-share-property.gql'

type UseSharePropertyProps = {
  listing: UseShareProperty_ListingFragment
}

export function useShareProperty(props: UseSharePropertyProps) {
  const { listing } = props
  const { isMobile, host } = useRequestData()
  const openModal = useOpenModal()

  const photos = listing.optimizedPhotos || listing.photos
  const modalPhoto = photos?.[0] ?? null
  const title = listing?.name || undefined
  const url = `${host}${listing?.urlPathname}`
  const subject = 'Check out this rental listing on Rent!'
  const address = listing.addressFull
  const body = `${title}, ${address} | via Rent.`
  const emailLink = encodeURI(
    `mailto:?to=&subject=${subject}&body=${body}\r\n${url}`
  )
  const modalProps = useMemo(
    () => ({ modalPhoto, title, url, emailLink, address }),
    [address, emailLink, modalPhoto, title, url]
  )

  const shareData = useMemo(
    () => ({ url, title, text: body }),
    [body, title, url]
  )

  const shareHandler = useCallback(async () => {
    if (navigator.canShare?.(shareData) && isMobile) {
      try {
        await navigator.share(shareData)
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err)
      }
    } else {
      openModal({
        id: ModalName.SHARE_PROPERTY,
        props: modalProps,
      })
    }
  }, [isMobile, modalProps, openModal, shareData])

  return { shareHandler }
}
