import { brandHostUrl } from '../config/brand-config'

export const utilityDisclaimerUtmCampaign = 'apartmentguide'

export const getFromEnd = true

export const markerUrl = `${brandHostUrl}/images/pdp-map-pin.png`

export const markerPosition = '22,56'

export const detailPropertyTypeSlug =
  ':propertyTypes(apartments|condos|townhomes|houses|apartment|condo|townhome|house|duplex|triplex|rental)'

export const showAltPdp = false
