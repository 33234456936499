export default function checkStreetViewMetadata(
  url: string,
  callback: (res: { metadata?: Record<string, string> | null }) => void
) {
  void fetch('/api/maps/check-metadata', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ url }),
  })
    .then((res) => res.json())
    .then(callback)
}
