import type { TourFromHome_ListingFragment } from '../detail/tour-from-home/__generated__/tour-from-home.gql'
import type { BaseTourItem } from '../detail/tour-from-home/tour-from-home-carousel'

function setAutoplayHdUrl(tourUrl: string) {
  if (tourUrl.toLowerCase().includes('matterport.com')) {
    try {
      const url = new URL(tourUrl)
      url.searchParams.set('play', '1')
      return url.href
    } catch {}
  }

  return tourUrl
}

export function getSRPHdTours(
  listing: TourFromHome_ListingFragment
): BaseTourItem[] {
  if (!listing?.hdTours?.length) {
    return []
  }

  return listing.hdTours.reduce((acc: BaseTourItem[], hdTour) => {
    if (!hdTour.url) {
      return acc
    }

    acc.push({
      caption: hdTour.name || '',
      tourUrl: hdTour.url ? setAutoplayHdUrl(hdTour.url) : '',
      type: '3d',
    })

    return acc
  }, [])
}
