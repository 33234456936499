import clsx from 'clsx'
import { memo, useMemo, useRef } from 'react'
import { ScrollSnapCarousel } from '../../components/carousel/scroll-snap-carousel'
import { getCloudinaryUrl } from '../../components/cloud-image/get-cloudinary-url'
import { ImageWithFallback } from '../../components/image-with-fallback/image-with-fallback'
import type { ListingCardCarousel_ListingFragment } from './__generated__/listing-card-carousel.gql'
import type { MarkerPopupMobile_ListingFragment } from './__generated__/marker-popup-mobile.gql'
import styles from './listing-card-scroll-snap-carousel.module.css'
import redesignButtonStyles from './listing-card-redesign-carousel.module.css'
import { getAltText } from './listing-card.utils'
import { useFeatureVariable } from '@rentpath/ab-testing-react'
import { yieldOrContinue } from 'main-thread-scheduling'
import { ListingCardDetailPageLink } from './listing-card-detail-page-link'
import { DIRECTION } from '../../components/carousel/scroll-snap-carousel.const'
import { ListingCardNoPhotosCarousel } from './listing-card-gallery-no-photos'

type ListingCardCarouselOwnProps = {
  lazyRenderSlides?: boolean
  listingId: string
  shouldUseFirstCarouselImageAsLCP?: boolean
  className?: string
  dataTagSection?: string
  isMobile?: boolean
  onFirstInteraction?: () => void
  onLinkClick: () => void
  disableSwipe?: boolean
  cityState?: string
}

type ListingCardCarouselProps = ListingCardCarousel_ListingFragment &
  ListingCardCarouselOwnProps &
  Pick<MarkerPopupMobile_ListingFragment, 'urlPathname'>

function ListingCardScrollSnapCarouselBase({
  lazyRenderSlides,
  listingId,
  photos: photoCollection,
  dataTagSection,
  className,
  urlPathname,
  isMobile,
  onFirstInteraction,
  onLinkClick,
  shouldUseFirstCarouselImageAsLCP,
  isUnpaid,
  name,
  cityState,
  location,
}: ListingCardCarouselProps) {
  const photos = useMemo(() => photoCollection ?? [], [photoCollection])
  const firstInteractionRef = useRef<boolean>(true)
  const isListingCardRedesignVersionTwo =
    useFeatureVariable<number>(['listing_card_redesign', 'version'], 0) === 2
  const carouselClassName = clsx(
    className,
    styles.carousel,
    isListingCardRedesignVersionTwo && styles.withUpdatedStyles
  )
  const buttonStyles = isListingCardRedesignVersionTwo
    ? redesignButtonStyles
    : styles

  const items = useMemo(
    () =>
      photos?.map((photo, index) => {
        if (!photo?.id || !urlPathname) {
          return null
        }

        const isLCP = shouldUseFirstCarouselImageAsLCP && index === 0
        return (
          <ListingCardDetailPageLink
            key={photo.id + index}
            className={styles.image}
            isMobile={isMobile}
            href={urlPathname}
            onClick={onLinkClick}
          >
            <ImageWithFallback
              id={`listing-card-carousel-${listingId}-${photo.id}-${index}`}
              width="350"
              height="240"
              priority={isLCP}
              fetchPriority={isLCP ? 'high' : 'low'}
              unoptimized
              src={getCloudinaryUrl(photo.id, 'lg', {
                isUnpaid: isUnpaid,
              })}
              alt={getAltText(name, index, cityState)}
              className={styles.image}
              fallbackImageSize="sm"
            />
          </ListingCardDetailPageLink>
        )
      }),
    [
      isMobile,
      isUnpaid,
      listingId,
      name,
      cityState,
      onLinkClick,
      photos,
      shouldUseFirstCarouselImageAsLCP,
      urlPathname,
    ]
  )

  if (!urlPathname) return null

  // Handle no photos or one photo
  if (!photos || photos.length === 0 || (photos.length === 1 && photos[0])) {
    return (
      <ListingCardNoPhotosCarousel
        listingId={listingId}
        photos={photos}
        dataTagSection={dataTagSection}
        buttonStyles={buttonStyles}
        carouselClassName={carouselClassName}
        urlPathname={urlPathname}
        isMobile={isMobile}
        onFirstInteraction={onFirstInteraction}
        onLinkClick={onLinkClick}
        shouldUseFirstCarouselImageAsLCP={shouldUseFirstCarouselImageAsLCP}
        isUnpaid={isUnpaid}
        name={name}
        cityState={cityState}
        location={location}
        firstPhoto={photos[0]}
      />
    )
  }

  return (
    <ScrollSnapCarousel
      lazy={lazyRenderSlides}
      onSlideChange={async function onSlideChange(idx, direction) {
        if (firstInteractionRef.current === true) {
          await yieldOrContinue('idle')
          onFirstInteraction?.()
          firstInteractionRef.current = false
        }

        if (direction) {
          await yieldOrContinue('idle')
          window.eventTracker?.track('click', {
            section: dataTagSection,
            item:
              direction === DIRECTION.NEXT
                ? 'photo_gallery_right_arrow'
                : 'photo_gallery_left_arrow',
          })
        } else {
          await yieldOrContinue('idle')
          window.eventTracker?.track('scroll', {
            section: dataTagSection,
            item: `photo-${photos?.[idx]?.id}`,
          })
        }
      }}
      className={carouselClassName}
      styles={{
        track: styles.track,
        nextBtn: buttonStyles.btnNext,
        prevBtn: buttonStyles.btnPrev,
      }}
      data-tag_section={dataTagSection}
      aria-label="Listing Card Gallery"
      name={`${listingId}-listing-card`}
    >
      {items}
    </ScrollSnapCarousel>
  )
}

export const ListingCardScrollSnapCarousel = memo(
  ListingCardScrollSnapCarouselBase
)
